<template>
  <v-select
    v-model="selectOption"
    :items="items"
    class="filter-select text-sm"
    dense
    hide-details
    item-text="name"
    item-value="id"
    label="Status"
    outlined
    single-line
    :disabled="value === 'Alocat'"
  >
  </v-select>
</template>

<script>
export default {
  name: "InlineEditLogStatus",
  inject: ["table"],
  props: {
    item: {
      required: true,
    },
    value: {
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      items: [
        {
          name: "In Asteptare",
          id: "In Asteptare",
        },
        {
          name: "Alocat",
          id: "Alocat",
        },
        {
          name: "Refuzat",
          id: "Refuzat",
        },
      ],
    };
  },
  computed: {
    selectOption: {
      get() {
        return this.value;
      },
      set(value) {
        this.loading = true;
        this.$http
          .patch(`voucher/log/${this.item.id}`, { status: value })
          .then(() => {
            this.table.loadItems();
          })
          .catch((err) => {
            this.$vs.notify({
              title: "Eroare",
              text: err.response.data.errors.status[0],
              color: "danger",
            });
            this.table.loadItems();
          })
          .finally(() => {
            this.loading = false;
          });
      },
    },
  },
};
</script>
