<template>
  <v-sheet>
    <cit-data-table
      :headers="headers"
      :includes="['voucher', 'customer','order']"
      disable-actions
      resource-path="voucher/log"
    >

      <template v-slot:item.status="{item,value}">
        <inline-edit-log-status :item="item" :value="value"/>
      </template>

    </cit-data-table>
  </v-sheet>
</template>

<script>
import CitDataTable from '@/components/layout/CitDataTable'
import InlineEditLogStatus from '@/views/Voucher/InlineEditLogStatus'

export default {
  name: 'PaymentRequestList',
  components: {
    InlineEditLogStatus,
    CitDataTable
  },
  data () {
    return {
      headers: [
        {
          text: 'Voucher',
          value: 'voucher.client_display_name',
          filterType: 'customer',
          filterName: 'voucher_id',
          sortable: false
        },
        {
          text: 'Client',
          value: 'customer.client_display_name',
          filterType: 'customer',
          filterName: 'customer_id',
          sortable: false
        },
        {
          text: 'Puncte',
          value: 'points',
          sortable: false
        },
        {
          text: 'Total Comanda',
          value: 'total',
          sortable: false
        },
        {
          text: 'Status',
          value: 'status',
          filterType: 'simple-select',
          filterOptions: [
            {
              text: 'In Asteptare',
              value: 'In Asteptare'
            },
            {
              text: 'Alocat',
              value: 'Alocat'
            },
            {
              text: 'Refuzat',
              value: 'Refuzat'
            }
          ],
          sortable: false
        },
        {
          text: 'Data',
          display: 'date',
          filterType: 'date-range',
          sortable: true,
          value: 'created_at'
        }
      ]
    }
  }

}
</script>
